import { ReactComponent as SvgOficina } from "../assets/icons/servicos/svg_oficina.svg";
import { ReactComponent as SvgFinanciamento } from "../assets/icons/servicos/svg_financiamento.svg";
import { ReactComponent as SvgLavagens } from "../assets/icons/servicos/svg_lavagens.svg";
import { ReactComponent as SvgSeguros } from "../assets/icons/servicos/svg_seguros.svg";
import { ReactComponent as SvgTestemunhos } from "../assets/icons/servicos/svg_testemunhos.svg";
import { ReactComponent as SvgGaleria } from "../assets/icons/servicos/svg_galeria.svg";
import { ReactComponent as SvgAlbumClientes } from "../assets/icons/servicos/svg_album_clientes.svg";
import { ReactComponent as SvgNoticias } from "../assets/icons/servicos/svg_noticias.svg";
import { ReactComponent as SvgCompramos } from "../assets/icons/servicos/svg_compramos.svg";
import { ReactComponent as SvgVendemos } from "../assets/icons/servicos/svg_vendemos.svg";
import { ReactComponent as SvgConsultadoria } from "../assets/icons/servicos/svg_consultadoria.svg";
import { ReactComponent as SvgAluguer } from "../assets/icons/servicos/svg_aluguer.svg";
import { ReactComponent as SvgGarantia } from "../assets/icons/servicos/svg_garantia.svg";

import financiamento_desktop from "../assets/img/servicos/desktop/img_financiamento.webp";
import financiamento_mobile from "../assets/img/servicos/mobile/img_financiamento.webp";
import compramos_desktop from "../assets/img/servicos/desktop/img_compramos.webp";
import compramos_mobile from "../assets/img/servicos/mobile/img_compramos.webp";
import album_clientes_desktop from "../assets/img/servicos/desktop/img_album_clientes.webp";
import album_clientes_mobile from "../assets/img/servicos/mobile/img_album_clientes.webp";
import aluguer_desktop from "../assets/img/servicos/desktop/img_aluguer.webp";
import aluguer_mobile from "../assets/img/servicos/mobile/img_aluguer.webp";
import consultadoria_desktop from "../assets/img/servicos/desktop/img_consultadoria.webp";
import consultadoria_mobile from "../assets/img/servicos/mobile/img_consultadoria.webp";
import galeria_desktop from "../assets/img/servicos/desktop/img_galeria.webp";
import galeria_mobile from "../assets/img/servicos/mobile/img_galeria.webp";
import garantia_desktop from "../assets/img/servicos/desktop/img_garantia.webp";
import garantia_mobile from "../assets/img/servicos/mobile/img_garantia.webp";
import lavagens_desktop from "../assets/img/servicos/desktop/img_lavagens.webp";
import lavagens_mobile from "../assets/img/servicos/mobile/img_lavagens.webp";
import noticias_desktop from "../assets/img/servicos/desktop/img_noticias.webp";
import noticias_mobile from "../assets/img/servicos/mobile/img_noticias.webp";
import oficina_desktop from "../assets/img/servicos/desktop/img_oficina.webp";
import oficina_mobile from "../assets/img/servicos/mobile/img_oficina.webp";
import seguros_desktop from "../assets/img/servicos/desktop/img_seguros.webp";
import seguros_mobile from "../assets/img/servicos/mobile/img_seguros.webp";
import testemunhos_desktop from "../assets/img/servicos/desktop/img_testemunhos.webp";
import testemunhos_mobile from "../assets/img/servicos/mobile/img_testemunhos.webp";

export const ServicosInfoJson = [
  "Oficina",
  "Aluguer",
  "Testemunhos",
  "Consultadoria",
  "Financiamento",
  "Seguros",
  "Lavagens",
  "Compramos",
  "Vendemos",
  "AlbumClientes",
  "Noticias",
  "Galeria",
  "Garantia",
];

export const imagensDesk = {
  Oficina: oficina_desktop,
  Financiamento: financiamento_desktop,
  Lavagens: lavagens_desktop,
  Seguros: seguros_desktop,
  Testemunhos: testemunhos_desktop,
  Galeria: galeria_desktop,
  AlbumClientes: album_clientes_desktop,
  Noticias: noticias_desktop,
  Compramos: compramos_desktop,
  Vendemos: compramos_desktop,
  Consultadoria: consultadoria_desktop,
  Aluguer: aluguer_desktop,
  Garantia: garantia_desktop,
};

export const imagensMob = {
  Oficina: oficina_mobile,
  Financiamento: financiamento_mobile,
  Lavagens: lavagens_mobile,
  Seguros: seguros_mobile,
  Testemunhos: testemunhos_mobile,
  Galeria: galeria_mobile,
  AlbumClientes: album_clientes_mobile,
  Noticias: noticias_mobile,
  Compramos: compramos_mobile,
  Vendemos: compramos_mobile,
  Consultadoria: consultadoria_mobile,
  Aluguer: aluguer_mobile,
  Garantia: garantia_mobile,
};

export const components = {
  Oficina: SvgOficina,
  Financiamento: SvgFinanciamento,
  Lavagens: SvgLavagens,
  Seguros: SvgSeguros,
  Testemunhos: SvgTestemunhos,
  Galeria: SvgGaleria,
  AlbumClientes: SvgAlbumClientes,
  Noticias: SvgNoticias,
  Compramos: SvgCompramos,
  Vendemos: SvgVendemos,
  Consultadoria: SvgConsultadoria,
  Aluguer: SvgAluguer,
  Garantia: SvgGarantia,
};
