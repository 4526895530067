import { Col, Container, Row } from "react-bootstrap";
import Spacer from "./Spacer";
import css from "./home.module.css";

export default function SectionZone(props) {
  return (
    <section className={props.clasName} id={props.id}>
      <Spacer clasName="d-block" height="30px" />
      <Spacer clasName="d-none d-lg-block" height="30px" />
      <Container>
        <Row>
          <Col xs={12} className="nopadding">
            {props.Titulo && (
              <h2 className={css.listagem_titulo}>{props.Titulo}</h2>
            )}
            {props.SubTitulo && (
              <h3 className={`${css.listagem_subtitulo} titulo-page`}>
                {props.SubTitulo}
              </h3>
            )}
          </Col>
        </Row>
      </Container>
      {props.Titulo && props.SubTitulo && (
        <>
          <Spacer
            clasName="d-block"
            height={` ${props.top ? props.top : "30"}px`}
          />
          <Spacer clasName="d-none d-lg-block" height="30px" />
        </>
      )}

      {props.children}
      <Spacer
        clasName="d-block"
        height={`${props.bottom ? props.bottom : "30"}px`}
      />
      <Spacer clasName="d-none d-lg-block" height="30px" />
    </section>
  );
}
