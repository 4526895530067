import { Fragment, lazy, Suspense } from "react";
import Marcas from "./marcas/Marcas";
import Vendidos from "./vendidos/Vendidos";

const AlbumHome = lazy(() => import("./albumHome/AlbumHome"));
const Destaques = lazy(() => import("./destaques/Destaques"));
const EmpresaHome = lazy(() => import("./empresa/EmpresaHome"));
const IntervaloMensalidade = lazy(() =>
  import("./intervaloMensalidade/IntervaloMensalidade")
);
const IntervaloPreco = lazy(() => import("./intervaloPreco/IntervaloPreco"));
const Listagem = lazy(() => import("./listagem/Listagem"));
const NoticiasHome = lazy(() => import("./noticiasHome/NoticiasHome"));
const Servicos = lazy(() => import("./servicos/Servicos"));
const TestemunhosHome = lazy(() => import("./testemunhosHome/TestemunhosHome"));
const Tipos = lazy(() => import("./tipos/Tipos"));
const Recentes = lazy(() => import("./utimasEntradas/Recentes"));

export default function SectionList({
  infoJson,
  allLista,
  filteredCarros,
  allListaCarros,
  setFilteredCarros,
  selectedItems,
  addToCompare,
  removeFromCompare,
  allinfoText,
  pontosVenda,
}) {
  const renderSwitch = (param) => {
    switch (param) {
      case "Destaques":
        return (
          <>
            {infoJson.Layout.Destaques.existe && (
              <>
                <Suspense fallback={<></>}>
                  <Destaques
                    allLista={allLista}
                    infoJson={infoJson}
                    selectedItems={selectedItems}
                    addToCompare={addToCompare}
                    removeFromCompare={removeFromCompare}
                    allinfoText={allinfoText}
                    tipoDestaque={1}
                    top={true}
                  />
                </Suspense>
              </>
            )}
          </>
        );
      case "UltimasEntradas":
        return (
          <>
            {infoJson.Layout.UltimasEntradas.existe && (
              <>
                {allLista.length !== 0 && (
                  <>
                    <Suspense fallback={<></>}>
                      <Recentes
                        allLista={allLista}
                        infoJson={infoJson}
                        allinfoText={allinfoText}
                      />
                    </Suspense>
                  </>
                )}
              </>
            )}
          </>
        );
      case "Vendidos":
        return (
          <>
            {infoJson.Layout.Vendidos.existe && (
              <>
                {allLista.length !== 0 && (
                  <>
                    <Suspense fallback={<></>}>
                      <Vendidos
                        allLista={allLista}
                        infoJson={infoJson}
                        allinfoText={allinfoText}
                      />
                    </Suspense>
                  </>
                )}
              </>
            )}
          </>
        );
      case "Marcas":
        return (
          <>
            {infoJson.Layout.Marcas.existe && (
              <>
                {allLista.length !== 0 && (
                  <>
                    <Suspense fallback={<></>}>
                      <Marcas allLista={allLista} infoJson={infoJson} />
                    </Suspense>
                  </>
                )}
              </>
            )}
          </>
        );
      case "Servicos":
        return (
          <>
            {infoJson.Layout.Servicos.existe && (
              <>
                <Suspense fallback={<></>}>
                  <Servicos infoJson={infoJson} allinfoText={allinfoText} />
                </Suspense>
              </>
            )}
          </>
        );
      case "Tipos":
        return (
          <>
            {infoJson.Layout.Tipos.existe && (
              <>
                {allLista.length !== 0 && (
                  <>
                    <Suspense fallback={<></>}>
                      <Tipos
                        allLista={allLista}
                        infoJson={infoJson}
                        allinfoText={allinfoText}
                      />
                    </Suspense>
                  </>
                )}
              </>
            )}
          </>
        );
      case "intervaloPreco":
        return (
          <>
            {infoJson.Layout.intervaloPreco.existe && (
              <>
                <Suspense fallback={<></>}>
                  <IntervaloPreco
                    infoJson={infoJson}
                    allLista={allLista}
                    allinfoText={allinfoText}
                  />
                </Suspense>
              </>
            )}
          </>
        );
      case "Listagem":
        return (
          <>
            {infoJson.Layout.Carros.existe && (
              <>
                <Suspense fallback={<></>}>
                  <Listagem
                    infoJson={infoJson}
                    filteredCarros={filteredCarros}
                    allListaCarros={allListaCarros}
                    allLista={allLista}
                    setFilteredCarros={setFilteredCarros}
                    selectedItems={selectedItems}
                    addToCompare={addToCompare}
                    removeFromCompare={removeFromCompare}
                    allinfoText={allinfoText}
                    pontosVenda={pontosVenda}
                  />
                </Suspense>
              </>
            )}
          </>
        );
      case "AlbumClientes":
        return (
          <>
            {infoJson.Layout.AlbumClientes.existe && (
              <>
                <Suspense fallback={<></>}>
                  <AlbumHome infoJson={infoJson} allinfoText={allinfoText} />
                </Suspense>
              </>
            )}
          </>
        );
      case "Noticias":
        return (
          <>
            {infoJson.Layout.Noticias.existe && (
              <>
                <Suspense fallback={<></>}>
                  <NoticiasHome infoJson={infoJson} allinfoText={allinfoText} />
                </Suspense>
              </>
            )}
          </>
        );
      case "Testemunhos":
        return (
          <>
            {infoJson.Layout.Testemunhos.existe && (
              <>
                <Suspense fallback={<></>}>
                  <TestemunhosHome
                    infoJson={infoJson}
                    allinfoText={allinfoText}
                  />
                </Suspense>
              </>
            )}
          </>
        );
      case "intervaloMensalidades":
        return (
          <>
            {infoJson.Layout.intervaloMensalidades.existe && (
              <>
                <Suspense fallback={<></>}>
                  <IntervaloMensalidade
                    infoJson={infoJson}
                    allLista={allLista}
                    allinfoText={allinfoText}
                  />
                </Suspense>
              </>
            )}
          </>
        );
      case "QuemSomos":
        return (
          <>
            {infoJson.Layout.QuemSomos.existe && (
              <>
                <Suspense fallback={<></>}>
                  <EmpresaHome infoJson={infoJson} allinfoText={allinfoText} />
                </Suspense>
              </>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {infoJson.Home.sort((a, b) => a.ordenador - b.ordenador).map((item) => {
        return <Fragment key={item.id}>{renderSwitch(item.nome)}</Fragment>;
      })}
    </>
  );
}
