import React, { Suspense } from "react";
import Snow from "./Snow";
import { getNeves } from "../../../api/apiConfigSite";
import useSWR from "swr";

export default function GestorNeves({ infoJson }) {
  const { data: shouldSnow, isLoading } = useSWR("neves", () =>
    getNeves(infoJson)
  );

  if (isLoading) {
    return null;
  }
  return (
    <>
      {shouldSnow.Activo && (
        <Suspense fallback={<></>}>
          <Snow infoJson={infoJson} shouldSnow={shouldSnow} />
        </Suspense>
      )}
    </>
  );
}
