import { useState, useEffect, memo } from "react";

const ImageLoad = memo(({ src, alt = "", ...props }) => {
  const [loading, setLoading] = useState(true);
  const [currentSrc, updateSrc] = useState("");

  useEffect(() => {
    // start loading original image
    const imageToLoad = new Image();
    imageToLoad.src = src;
    imageToLoad.onload = () => {
      // When image is loaded replace the src and set loading to false
      setLoading(false);
      updateSrc(src);
    };
  }, [src]);

  return (
    <img
      src={currentSrc}
      style={{
        opacity: loading ? 0.5 : 1,
        transition: "opacity .15s linear",
      }}
      alt={alt}
      {...props}
    />
  );
});

export default ImageLoad;
