// Importações das dependências e utilitários necessários
import { ApiLinkImagens } from "../../config/config";

import { tratarLocation } from "./tratarInfoNoticias";

// Função para gerar o esquema de dados para um carro
const schemaCar = (viatura) => {
  // Desestruturação dos dados da viatura
  const {
    Marca,
    Modelo,
    Viatura,
    textMotorizacao,
    VIN,
    Ficheiros,
    Vendido,
    NaoExportarPreco,
    PrecoPromo,
    Preco,
    Ano,
    textKm,
    Cor,
    Combustivel,
    Transmissao,
    Portas,
    Lugares,
    location,
  } = viatura;

  // Tratamento da motorização
  const motorizacao = textMotorizacao;

  // Construção do esquema de carro
  const carSchema = {
    "@context": "https://schema.org",
    "@type":
      Viatura === "carros"
        ? "Car"
        : Viatura === "motas"
        ? "Motorcycle"
        : "Product",
    name: `${Marca.Nome} ${Modelo?.Nome ? Modelo?.Nome : ""} ${motorizacao}`,
    vehicleIdentificationNumber: VIN ? VIN : "00000000000000000",
    image: Ficheiros.map((item) => item.Ficheiro),
    url: location,
    offers: {
      "@type": "Offer",
      availability: "https://schema.org/InStock",
      // Condição para o preço do carro
      price:
        Vendido !== true && NaoExportarPreco !== true && PrecoPromo !== ""
          ? PrecoPromo
          : NaoExportarPreco !== true && Preco !== ""
          ? Preco
          : "0",
      priceCurrency: "EUR",
    },
    itemCondition: "https://schema.org/NewCondition",
    brand: { "@type": "Brand", name: Marca?.Nome },
    model: Modelo.Nome,
    vehicleConfiguration: motorizacao,
    vehicleModelDate: Ano,
    mileageFromOdometer: {
      "@type": "QuantitativeValue",
      value: textKm,
      unitCode: "SMI",
    },
    color: Cor.Nome,
    driveWheelConfiguration: "https://schema.org/FourWheelDriveConfiguration",
    vehicleEngine: {
      "@type": "EngineSpecification",
      fuelType: Combustivel?.Nome,
    },
    vehicleTransmission: Transmissao?.Nome,
    numberOfDoors: Portas?.Nome,
    vehicleSeatingCapacity: Lugares?.Nome
      ? Lugares?.Nome.replace("Lugares", "")
      : "",
  };

  return [carSchema];
};

// Função para gerar o esquema de dados para um artigo de blog
const schemaBlog = (blog) => {
  // Construção do esquema de artigo de blog
  const blogSchema = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: blog.Titulo,
    image: blog.Ficheiros.map((item) => item.Ficheiro),
    url: tratarLocation(blog.CodElemento, blog.Titulo, "noticias"),
  };

  return [blogSchema];
};

// Função para gerar o esquema de dados para a página inicial
const schemaHome = (home, url, listagem) => {
  // Construção do esquema da página inicial
  const homeSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: home.NomeEmpresa,
    url: home.Website + url,
    logo: `${ApiLinkImagens}/partilha_fb`,
    address: {
      "@type": "PostalAddress",
      streetAddress: home.Rua,
      addressLocality: home.Localidade,
      postalCode: home.cp4 && home.cp3 ? `${home.cp4}-${home.cp3}` : "0000-000",
      addressCountry: "Portugal",
    },
    contactPoint: {
      "@type": "ContactPoint",
      telephone: home.Telemovel,
      contactType: "Atendimento ao Cliente",
    },
  };

  return [
    homeSchema,
    ...(listagem.existe ? schemaListagem(listagem.array, listagem.title) : []),
  ];
};

// Função para gerar o esquema de dados para os contatos
const schemaContact = (contact, url, titulo) => {
  // Construção do esquema de contatos
  const contactSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: titulo,
    url: contact.Website + url,
    contactPoint: {
      "@type": "ContactPoint",
      telephone: contact.Telemovel,
      contactType: "Atendimento ao Cliente",
    },
  };

  return [contactSchema];
};

// Função para gerar o esquema de dados para uma lista de itens
const schemaListagem = (listagem, titulo) => {
  // Construção do esquema da lista de itens
  const listagemSchema = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    name: titulo,
    itemListElement: listagem.map((item, index) => ({
      position: index,
      "@type": "ListItem",
      item: schemaCar(item),
    })),
  };

  return [listagemSchema];
};

// Função principal que seleciona o esquema de dados com base na categoria
const selectSchema = (categoria) => {
  let schema;

  if (!categoria) {
    return 0;
  }

  // Seleciona o tipo de esquema com base na categoria
  if (
    categoria.tipo === "viatura" &&
    Object.keys(categoria.array).length !== 0
  ) {
    schema = schemaCar(categoria.array);
  }

  if (
    categoria.tipo === "noticias" &&
    Object.keys(categoria.array).length !== 0
  ) {
    schema = schemaBlog(categoria.array);
  }

  if (categoria.tipo === "home" && categoria.array.length !== 0) {
    schema = schemaHome(categoria.array[0], categoria.url, categoria.listagem);
  }

  if (categoria.tipo === "contactos" && categoria.array.length !== 0) {
    schema = schemaContact(categoria.array[0], categoria.url, categoria.titulo);
  }

  if (categoria.tipo === "listagem" && categoria.array.length !== 0) {
    schema = schemaListagem(categoria.array, categoria.title);
  }

  return schema;
};

// Exportação da função selectSchema para uso externo
export { selectSchema };
