import { useEffect } from "react";
import OneSignal from "react-onesignal";

const useOneSignal = (infoJson) => {
  useEffect(() => {
    if (infoJson.Stand?.OneSignal?.existe) {
      OneSignal.init({
        appId: infoJson.Stand?.OneSignal?.appId,
      });
      OneSignal.showSlidedownPrompt();
    }
  }, [infoJson.Stand?.OneSignal?.existe, infoJson.Stand?.OneSignal?.appId]);
};

export default useOneSignal;
