export const lightTheme = {
  body: "#ffffff",
  bodyModal: "#ffffff",
  boxCar: "#ffffff",
  boxCarMore: "#f7f7f7",
  boxCarMoreDot: "#222222",
  text: "#000000",
  border: "#F2F2F2",
  borderInput: "#F2F2F2",
  InputPesquisa: "#ffffff",
  InputPesquisaLiga: "#ffffff",
  boxShadow: "0 2px 9px 0 rgba(28, 76, 68, 0.07)",
  btnPartilhaColor: "#000000",
  btnPartilhaBg: "#F5F5F5",
  btnPartilhaBgHover: "#b7b7b7",
  btnPartilhaBgcolorHover: "#4f4f4f",
  linha: "#EAEAEA",
  boxShadowOb: "0 0 25px -5px rgba(0,0,0,0.26)",
  dots: "#dbdbdb)",
  dotsAtive: "#000000",
  tiposHover: "#ffffff",
  lineOption: "#f2f2f2",
  sectionComparar: "#f2f2f2",
  bgMore: "#f4f4f4",
  disable: "#e9ecef",
};

export const darkTheme = {
  body: "#111111",
  bodyModal: "#1A1A1A",
  boxCar: "#212121",
  boxCarMore: "#272727",
  boxCarMoreDot: "#ffffff",
  text: "#ffffff",
  border: "transparent",
  borderInput: "#2D2D2D",
  InputPesquisa: "#212121",
  InputPesquisaLiga: "#2f2f2f",
  boxShadow: "none",
  btnPartilhaColor: "#ffffff",
  btnPartilhaBg: "#282828",
  btnPartilhaBgHover: "#707070",
  btnPartilhaBgcolorHover: "#ffffff",
  linha: "#2D2D2D",
  boxShadowOb: "none",
  dots: "#dbdbdb)",
  dotsAtive: "#ffffff",
  tiposHover: "#212121",
  lineOption: "#2d2d2d",
  sectionComparar: "#2D2D2D",
  bgMore: "#151515",
  disable: "#272A2C",
};
