const codigoPostal = (cp1, cp2) => {
  let codigoPostal = "";

  if (cp1 && cp2) {
    codigoPostal = cp1 + " - " + cp2;
  }

  return codigoPostal;
};
const switchEstado = (name) => {
  switch (name) {
    case "Novo":
      return "NEW";
    case "Serviço":
      return "CPO";
    default:
      return "Used";
  }
};

const switchTransmissao = (cod) => {
  switch (cod) {
    case 32:
      return "Other";
    case 39:
      return "Other";
    case 41:
      return "Other";
    case 40:
      return "Manual";
    case 33:
      return "Manual";
    case 34:
      return "Manual";
    case 35:
      return "Manual";
    case 36:
      return "Automatic";
    case 37:
      return "Automatic";
    case 38:
      return "Automatic";
    case 43:
      return "Automatic";
    case 44:
      return "Automatic";
    case 42:
      return "Other";
    default:
      return "Other";
  }
};
const switchTipo = (name) => {
  switch (name) {
    case "Descapotável":
      return "Convertible";
    case "Coupé":
      return "Coupe";
    case "SUV / Monovolume":
      return "Crossover";
    case "Utilitários / Sedan":
      return "Minivan";
    case "Pick Up":
      return "Truck";
    case "Todo-o-Terreno":
      return "Truck";
    case "Berlina":
      return "Sedan";
    case "Carrinha":
      return "Wagon";
    default:
      return "Other";
  }
};

const switchCombustivel = (name) => {
  switch (name) {
    case "Híbrido Gasolina":
      return "Hybrid";
    case "Híbrido Gasóleo":
      return "Hybrid";
    case "Híbrido":
      return "Hybrid";
    case "GPL":
      return "Other";
    case "Gasolina":
      return "Gasoline";
    case "Gasóleo":
      return "Diesel";
    case "Electrico":
      return "Electric";
    default:
      return "Other";
  }
};

export {
  codigoPostal,
  switchEstado,
  switchTransmissao,
  switchTipo,
  switchCombustivel,
};
