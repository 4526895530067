import useMediaQuery from "../../../hooks/useMediaQuery ";
import Spacer from "../Spacer";
import css from "./banners.module.css";
import ReactPlayer from "react-player/youtube";

export default function VideoBanner({ Src, existe }) {
  const isMobile = useMediaQuery("(max-width: 991px)");

  if (typeof Src === "string") {
    return (
      <>
        {existe && <Spacer height="85px" />}

        <ReactPlayer
          data-testid="video-youtube"
          className={css.bannerVideo}
          width="100%"
          height="100%"
          url={Src}
          playing={true}
          controls={false}
          loop={true}
          volume={0}
          muted={true}
          config={{
            youtube: {
              playerVars: { rel: 0, showinfo: 0 },
            },
          }}
        />
      </>
    );
  }

  if (typeof Src === "object" && Src !== null && !Array.isArray(Src)) {
    return (
      <>
        {existe && <Spacer height="85px" />}
        {isMobile ? (
          <ReactPlayer
            data-testid="video-youtube"
            className={css.banner}
            width="100%"
            height="100%"
            url={Src.mobile}
            playing={true}
            controls={false}
            loop={true}
            volume={0}
            muted={true}
            config={{
              youtube: {
                playerVars: { rel: 0, showinfo: 0 },
              },
            }}
          />
        ) : (
          <ReactPlayer
            data-testid="video-youtube"
            className={css.banner}
            width="100%"
            height="100%"
            url={Src.desktop}
            playing={true}
            controls={false}
            loop={true}
            volume={0}
            muted={true}
            config={{
              youtube: {
                playerVars: { rel: 0, showinfo: 0 },
              },
            }}
          />
        )}
      </>
    );
  }
}
