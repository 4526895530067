import { Container } from "react-bootstrap";
import SectionZone from "../SectionZone";
import CarrouselVendidos from "./CarrouselVendidos";
import { useEffect, useState } from "react";

export default function Vendidos({ allLista, infoJson, allinfoText }) {
  const [alltexto, setAlltexto] = useState({
    Titulo: "",
    SubTitulo: "",
    Zona: "",
    Url: "",
    MetaTitle: "",
    MetaDescription: "",
  });

  useEffect(() => {
    allinfoText.UltimasVendidas && setAlltexto(allinfoText.UltimasVendidas);
  }, [allinfoText]);

  return (
    <>
      <SectionZone
        Titulo={alltexto.Titulo !== "" ? alltexto.Titulo : "Últimas Vendidas"}
        SubTitulo={
          alltexto.SubTitulo !== "" ? alltexto.SubTitulo : "os mais recentes"
        }
        clasName={`section-recentes`}
      >
        <Container fluid="xxxl" className={`position-relative noPadding-xs`}>
          {allLista.length !== 0 && (
            <CarrouselVendidos allLista={allLista} infoJson={infoJson} />
          )}
        </Container>
      </SectionZone>
    </>
  );
}
