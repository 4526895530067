import { useEffect } from "react";

const useScriptWidgetsWhats = (infoJson) => {
  useEffect(() => {
    if (
      Object.keys(infoJson).length !== 0 &&
      infoJson.Stand.WidgetWhats.existe
    ) {
      const script = document.createElement("script");
      script.src = "https://s.widgetwhats.com/wwwa.js";
      script.async = true;
      script.setAttribute("data-wwwa", `${infoJson.Stand.WidgetWhats.dataWwa}`);

      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [infoJson]);
};

export default useScriptWidgetsWhats;
