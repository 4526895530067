import { useEffect, useState } from "react";
import Snowfall from "react-snowfall";
import {
  densidadeFestividades,
  imagensFestividades,
  tamanhoFestividades,
} from "./icons";

export default function Snow({ infoJson, shouldSnow }) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    let images = [];

    const svgArray = shouldSnow.Svg.split(",")
      .map((nome) => nome.trim())
      .filter(Boolean);

    svgArray.forEach((nome, index) => {
      const svg = imagensFestividades(shouldSnow.Cor)[nome];
      const snowflake = document.createElement("img");
      snowflake.src = `data:image/svg+xml;base64,${btoa(svg)}`;
      images = [...images, snowflake];
    });

    setImages(images);
  }, [shouldSnow.Svg, shouldSnow.Cor]);

  return (
    <>
      <Snowfall
        style={{
          zIndex: "999999",
          position: "fixed",
          width: "100vw",
          height: "100vh",
        }}
        // color="red"
        snowflakeCount={densidadeFestividades[shouldSnow.Densidade]}
        radius={tamanhoFestividades[shouldSnow.Tamanho]}
        speed={[0, 2.0]}
        wind={[-1, 1.5]}
        rotationSpeed={[-1.0, 5.0]}
        images={images}
      />
    </>
  );
}
