import { Link } from "react-router-dom";
import { yScrollContext } from "../restoreScroll/yScroll";
import { useContext } from "react";

export const NewLinkNoticias = (props) => {
  const { yScroll } = useContext(yScrollContext);
  return (
    <>
      {props.infoJson.Noticias.externas ? (
        <a
          href={props.location}
          aria-label={`Link`}
          target="_blank"
          rel="noreferrer"
        >
          {props.children}
        </a>
      ) : (
        <Link
          aria-label={`Link`}
          to={props.location}
          state={{
            backward: {
              yscroll: yScroll,
            },
          }}
        >
          {props.children}
        </Link>
      )}
    </>
  );
};

export const NewLinkServicos = (props) => {
  return (
    <>
      {props.location ? (
        props.externas ? (
          <a
            href={props.location}
            aria-label={`Link`}
            target="_blank"
            rel="noreferrer"
          >
            {props.children}
          </a>
        ) : (
          <Link aria-label={`Link`} to={props.location}>
            {props.children}
          </Link>
        )
      ) : (
        props.children
      )}
    </>
  );
};
