import { useEffect, useState } from "react";

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = useState(() => {
    try {
      const items = JSON.parse(localStorage.getItem(localStorageKey));
      if (items) {
        return items;
      }
      return "";
    } catch (err) {
      return "";
    }
  });

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value, localStorageKey]);

  return [value, setValue];
};

export default useStateWithLocalStorage;
