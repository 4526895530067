import { createContext, useState } from "react";

import arr from "../../config/metaTags.js";

// declare MetaTagsContext as a Context object
export const MetaTagsContext = createContext();

export const MetaTagsProvider = ({ children }) => {
  const [tags, setTags] = useState(arr);

  return (
    <MetaTagsContext.Provider
      value={{ stateMetaTags: tags, SetMetaTags: setTags }}
    >
      {children}
    </MetaTagsContext.Provider>
  );
};

export default MetaTagsContext;
