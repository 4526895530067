import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import PesquisaBox from "./pesquisa/PesquisaBox";
import Banners from "./banner/Banners";

import SectionList from "./SectionList";
import css from "./home.module.css";
import { LimparToUrl } from "../../helpers/tratarInfoCarros";
import { useParams } from "react-router";
import PopUp from "./popup/PopUp";
import DestaquesBanner from "./destaques/DestaquesBanner";
import VideoBanner from "./banner/VideoBanner";
import AuthContext from "../../hooks/AuthContext";
import Spacer from "./Spacer";
import { ApiLinkImagens } from "../../../config/config";
import Destaques from "./destaques/Destaques";
import { createObjectMetatags } from "../../helpers/tratarInfoEmpresa";
import ImageLoad from "../../hooks/ImageLoad";

export default function Home({
  infoJson,
  setMetaTags,
  selectedItems,
  addToCompare,
  removeFromCompare,

  allinfoText,
}) {
  const { state, dispatch } = useContext(AuthContext);

  const [filteredCarros, setFilteredCarros] = useState([]);
  let { viatura, marca, modelo } = useParams();
  const location = useLocation();
  const [alltexto, setAlltexto] = useState({
    Titulo: "",
    SubTitulo: "",
    Zona: "",
    Url: "",
    MetaTitle: "",
    MetaDescription: "",
  });

  useEffect(() => {
    dispatch({ type: "ExistHome", list: true });
  }, [dispatch]);

  useEffect(() => {
    allinfoText.Home && setAlltexto(allinfoText.Home);
  }, [allinfoText]);

  useEffect(() => {
    let Morada;
    if (infoJson.Stand.Distrito === infoJson.Stand.Concelho) {
      Morada = infoJson.Stand.Distrito;
    }
    if (infoJson.Stand.Distrito !== infoJson.Stand.Concelho) {
      Morada = infoJson.Stand.Concelho + ", " + infoJson.Stand.Distrito;
    }

    let arrMarcaNome = [];
    let arrMarcaId = [];
    let arrModeloNome = [];
    let arrModeloId = [];

    if (marca) {
      // cria array
      let arrMarca;
      if (marca.includes("--")) {
        arrMarca = marca.split("--");
      } else {
        arrMarca = [marca];
      }

      arrMarca.forEach((itemMarca) => {
        const filterItemMarca = state.allLista
          .filter(
            (carro) => itemMarca === LimparToUrl(carro.Marca.Nome.toLowerCase())
          )
          .sort(function (a, b) {
            if (a.Marca.Nome < b.Marca.Nome) {
              return -1;
            }
            if (a.Marca.Nome > b.Marca.Nome) {
              return 1;
            }
            return 0;
          })
          .reduce((accum, carro) => {
            const accumulator = [...accum];
            if (!accumulator.some((item) => item.Marca.Id === carro.Marca.Id)) {
              accumulator.push(carro);
            }
            return accumulator;
          }, [])
          .map((item) => item.Marca.Id);

        arrMarcaId = [...arrMarcaId, ...filterItemMarca];
      });
    }

    if (modelo) {
      // cria array
      let arrModelo;
      if (modelo.includes("--")) {
        arrModelo = modelo.split("--");
      } else {
        arrModelo = [modelo];
      }

      arrModelo.forEach((itemModelo) => {
        const filterItemModelo = state.allLista
          .filter(
            (carro) =>
              itemModelo === LimparToUrl(carro.Modelo.Nome.toLowerCase())
          )
          .sort(function (a, b) {
            if (a.Modelo.Nome < b.Modelo.Nome) {
              return -1;
            }
            if (a.Modelo.Nome > b.Modelo.Nome) {
              return 1;
            }
            return 0;
          })
          .reduce((accum, carro) => {
            const accumulator = [...accum];
            if (
              !accumulator.some((item) => item.Modelo.Id === carro.Modelo.Id)
            ) {
              accumulator.push(carro);
            }
            return accumulator;
          }, [])
          .map((item) => item.Modelo.Id);

        arrModeloId = [...arrModeloId, ...filterItemModelo];
      });
    }

    const MarcasComModelo = state.allLista
      .filter((carro) => {
        return arrModeloId.includes(carro.Modelo.Id);
      })
      .map((carro) => {
        return carro.Marca.Id;
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item === carro)) {
          accumulator.push(carro);
        }
        return accumulator;
      }, []);

    const MarcasSemMarcasComModelo = arrMarcaId.filter((carro) => {
      return !MarcasComModelo.includes(carro);
    });

    if (
      arrMarcaId &&
      arrMarcaId.length > 0 &&
      arrModeloId &&
      arrModeloId.length > 0
    ) {
      const nomeModelo = state.allLista
        .filter((carro) => arrModeloId.includes(carro.Modelo.Id))
        .sort(function (a, b) {
          if (a.Modelo.Nome < b.Modelo.Nome) {
            return -1;
          }
          if (a.Modelo.Nome > b.Modelo.Nome) {
            return 1;
          }
          return 0;
        })
        .reduce((accum, carro) => {
          const accumulator = [...accum];
          if (!accumulator.some((item) => item.Modelo.Id === carro.Modelo.Id)) {
            accumulator.push(carro);
          }
          return accumulator;
        }, [])
        .map((item) => `${item.Marca.Nome} ${item.Modelo.Nome}`);

      arrModeloNome = nomeModelo;

      const nomeMarca = state.allLista
        .filter((carro) => MarcasSemMarcasComModelo.includes(carro.Marca.Id))

        .sort(function (a, b) {
          if (a.Marca.Nome < b.Marca.Nome) {
            return -1;
          }
          if (a.Marca.Nome > b.Marca.Nome) {
            return 1;
          }
          return 0;
        })
        .reduce((accum, carro) => {
          const accumulator = [...accum];
          if (!accumulator.some((item) => item.Marca.Id === carro.Marca.Id)) {
            accumulator.push(carro);
          }
          return accumulator;
        }, [])
        .map((item) => item.Marca.Nome);

      arrMarcaNome = nomeMarca;
    } else {
      if (arrMarcaId && arrMarcaId.length > 0) {
        const nomeMarca = state.allLista
          .filter((carro) => arrMarcaId.includes(carro.Marca.Id))

          .sort(function (a, b) {
            if (a.Marca.Nome < b.Marca.Nome) {
              return -1;
            }
            if (a.Marca.Nome > b.Marca.Nome) {
              return 1;
            }
            return 0;
          })
          .reduce((accum, carro) => {
            const accumulator = [...accum];
            if (!accumulator.some((item) => item.Marca.Id === carro.Marca.Id)) {
              accumulator.push(carro);
            }
            return accumulator;
          }, [])
          .map((item) => item.Marca.Nome);
        arrMarcaNome = nomeMarca;
      }
    }

    let description = "";
    if (arrMarcaNome.length !== 0 && arrModeloNome.length !== 0) {
      // marca e modelo
      description =
        `Descubra já toda a nossa gama de Viaturas Usadas ${arrMarcaNome.toString()} e ${arrModeloNome.toString()}.` +
        ` Vendemos viaturas para todo o país. Consulte aqui todo o nosso stock ${arrMarcaNome.toString()} e ${arrModeloNome.toString()}.` +
        ` em ${infoJson.Stand.Concelho}.`;
    } else if (arrMarcaNome.length !== 0) {
      // marca
      description =
        `Descubra já toda a nossa gama de Viaturas Usadas ${arrMarcaNome.toString()}.` +
        ` Vendemos viaturas para todo o país.` +
        ` Consulte aqui todo o nosso stock ${arrMarcaNome.toString()} em ${
          infoJson.Stand.Concelho
        }.`;
    } else if (arrModeloNome.length !== 0) {
      // marca
      description =
        `Descubra já toda a nossa gama de Viaturas Usadas ${arrModeloNome.toString()}.` +
        ` Vendemos viaturas para todo o país.` +
        ` Consulte aqui todo o nosso stock ${arrModeloNome.toString()} em ${
          infoJson.Stand.Concelho
        }.`;
    } else if (viatura) {
      description =
        `Descubra já toda a nossa gama de Viaturas Usadas ${viatura
          .replace(/-/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase())}.` +
        ` Consulte aqui todo o nosso stock de ${viatura} em ${Morada}`;
    } else {
      //Listagem
      description = alltexto.MetaDescription
        ? alltexto.MetaDescription
        : `Procura automóvel? Escolha com Confiança. Stand de Carros Usados e Seminovos.` +
          ` Vendemos viaturas de qualidade para todo o país. Visite-nos em ${Morada}!`;
    }

    let tags = [];

    if (arrMarcaNome.length !== 0 && arrModeloNome.length !== 0) {
      // marca e modelo
      tags = [
        `Stand usados ${infoJson.Stand.Concelho}`,
        infoJson.Stand.Distrito,
        ...arrMarcaNome,
        ...arrModeloNome,
        "carros usados",
        "automoveis usados",
        `usados ${infoJson.Stand.Nome}`,
        "stand multimarcas",
        "automoveis usados",
      ];
    } else if (arrMarcaNome.length !== 0) {
      // marca
      tags = [
        `Stand usados ${infoJson.Stand.Concelho}`,
        infoJson.Stand.Distrito,
        ...arrMarcaNome,
        "carros usados",
        "automoveis usados",
        `usados ${infoJson.Stand.Nome}`,
        "stand multimarcas",
        "automoveis usados",
      ];
    } else {
      //Listagem
      tags = [
        `carros usados ${infoJson.Stand.Distrito}`,
        `stand usados ${infoJson.Stand.Distrito}`,
        `automoveis usados ${infoJson.Stand.Distrito}`,
        "stand semi-novos",
        "viaturas usadas",
        `Stands ${infoJson.Stand.Distrito}`,
      ];
    }

    let title;

    if (arrMarcaNome.length !== 0 && arrModeloNome.length !== 0) {
      // marca e modelo
      title = `Usados ${arrMarcaNome.toString()} e ${arrModeloNome.toString()} | Carros Usados em ${Morada} - ${
        infoJson.Stand.Nome
      }`;
    } else if (arrMarcaNome.length !== 0) {
      // marca
      title = `Usados ${arrMarcaNome.toString()} | Carros Usados em ${Morada} - ${
        infoJson.Stand.Nome
      }`;
    } else if (arrModeloNome.length !== 0) {
      // marca
      title = `Usados ${arrModeloNome.toString()} | Carros Usados em ${Morada} - ${
        infoJson.Stand.Nome
      }`;
    } else if (viatura) {
      title = `${viatura
        .replace(/-/g, " ")
        .replace(/\b\w/g, (char) =>
          char.toUpperCase()
        )} Usados | Carros Usados em ${Morada}`;
    } else {
      //Listagem
      title = alltexto.MetaTitle
        ? alltexto.MetaTitle
        : `${infoJson.Stand.Nome} - Stand Carros Usados em  ${Morada}`;
    }

    createObjectMetatags(
      infoJson,
      {
        Titulo: "",
        Url: "",
      },
      {
        titulo: title,
        url: location.pathname,
      },
      {
        title: title,
        tags: tags,
        description: description,
      },
      setMetaTags,
      "",
      {
        tipo: "home",
        array: state.infoPrincipal,
        url: location.pathname,
        listagem: {
          existe: infoJson.Layout.Carros.existe,
          array: filteredCarros,
          title: title,
        },
      }
    );
  }, [
    state.infoPrincipal,
    state.allLista,
    infoJson,
    marca,
    modelo,
    setMetaTags,
    location.pathname,
    alltexto,
    filteredCarros,
    viatura,
  ]);

  return (
    <>
      <PopUp infoJson={infoJson} />
      {infoJson.Banner.Existe && <Banners infoJson={infoJson} />}
      {infoJson.Banner.Video.Existe && (
        <VideoBanner
          Src={infoJson.Banner.Video.Src}
          existe={!infoJson.Layout.Transparente.existe}
        />
      )}

      {infoJson.Layout.Destaques.destaquesBanner.existe &&
        infoJson.Layout.Destaques.tipo !== 3 &&
        !infoJson.Banner.Existe &&
        !infoJson.Banner.Video.Existe && (
          <>
            <Spacer clasName="d-block" height="40px" />
          </>
        )}
      <>
        {!infoJson.Banner.Existe &&
          infoJson.Layout.Destaques.destaquesBanner.existe && (
            <>
              <DestaquesBanner
                infoJson={infoJson}
                selectedItems={selectedItems}
                addToCompare={addToCompare}
                removeFromCompare={removeFromCompare}
                allinfoText={allinfoText}
              />
            </>
          )}
      </>

      {infoJson.Layout.Destaques.destaquesBanner.existe &&
        infoJson.Layout.Destaques.tipo !== 3 &&
        !infoJson.Stand.PopUp.Existe &&
        !infoJson.Banner.Existe &&
        !infoJson.Banner.Video.Existe && (
          <>
            <Spacer clasName="d-block" height="50px" />
          </>
        )}

      <section className={`${infoJson.Banner.Interior ? css.BannerInt : ""}`}>
        {infoJson.Banner.Interior && (
          <ImageLoad
            src={`${ApiLinkImagens}/backg`}
            alt="Banner interior"
            className={css.BannerIntImage}
          />
        )}
      </section>
      {((!infoJson.Stand.PopUp.Existe &&
        !infoJson.Banner.Existe &&
        !infoJson.Banner.Video.Existe &&
        !infoJson.Layout.Destaques.destaquesBanner.existe) ||
        (state.ArrayDestaques.length === 0 &&
          !infoJson.Banner.Existe &&
          infoJson.Layout.Destaques.destaquesBanner.existe)) && (
        <>
          <Spacer clasName="d-block" height="200px" />
        </>
      )}
      <>
        {infoJson.Banner.Existe &&
          infoJson.Layout.Destaques.destaquesBanner.existe && (
            <>
              <Destaques
                allLista={state.allLista}
                infoJson={infoJson}
                selectedItems={selectedItems}
                addToCompare={addToCompare}
                removeFromCompare={removeFromCompare}
                allinfoText={allinfoText}
                tipoDestaque={
                  infoJson.Layout.Destaques.destaquesBanner.TipoDestaques
                }
                top={true}
              />
              <Spacer clasName="d-block" height="50px" />
            </>
          )}
      </>

      <PesquisaBox
        setFilteredCarros={setFilteredCarros}
        infoJson={infoJson}
        home={
          infoJson.Stand.PopUp.Existe ||
          infoJson.Banner.Existe ||
          infoJson.Banner.Video.Existe ||
          (infoJson.Layout.Destaques.destaquesBanner.existe &&
            infoJson.Layout.Destaques.tipo === 3)
        }
      />
      <>
        {infoJson.Banner.Existe &&
          infoJson.Layout.Destaques.destaquesBanner.existe && (
            <>
              <Spacer clasName="d-block" height="50px" />
            </>
          )}
      </>
      <SectionList
        infoJson={infoJson}
        allLista={state.allLista}
        filteredCarros={filteredCarros}
        allListaCarros={state.allCarros}
        setFilteredCarros={setFilteredCarros}
        selectedItems={selectedItems}
        addToCompare={addToCompare}
        removeFromCompare={removeFromCompare}
        allinfoText={allinfoText}
      />
    </>
  );
}
