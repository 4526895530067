import Spacer from "../Spacer";
import Destaques from "./Destaques";

export default function DestaquesBanner({
  infoJson,
  selectedItems,
  addToCompare,
  removeFromCompare,

  allinfoText,
}) {
  return (
    <>
      <div className={`p-0 position-relative container-fluid position`}>
        <Spacer height="85px" clasName="d-lg-none" />
        <>
          <Destaques
            infoJson={infoJson}
            selectedItems={selectedItems}
            addToCompare={addToCompare}
            removeFromCompare={removeFromCompare}
            allinfoText={allinfoText}
            autoPlay={true}
            tipoDestaque={
              infoJson.Layout.Destaques.destaquesBanner.TipoDestaques
            }
            top={false}
          />
        </>
      </div>
    </>
  );
}
