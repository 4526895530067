const metatags = {
  AlbumClientes: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `próximas novidades`,
      `novas informações`,
      `próximos desenvolvimentos`,
      `depoimentos`,
      `informações`,
      `__TITLE__`,
      `Stand usados __CONCELHO__`,
      `__DISTRITO__`,
      `usados __EMPRESA__`,
      `__EMPRESA__, __MORADA__`,
      `__EMPRESA__`,
    ],
    description: "Na __EMPRESA__ disponibilizamos esta página para __TITLE__",
  },
  Empresa: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `Stand usados __CONCELHO__`,
      "__DISTRITO__",
      `__TITLE__`,
      "auto usados",
      "carros usados",
      "stand semi-novos",
      "viaturas usadas",
      `usados __EMPRESA__`,
      "stand multimarcas",
      `__EMPRESA__, __MORADA__`,
      `__EMPRESA__`,
    ],
    description:
      "Procura automóvel? Escolha com Confiança. Stand de Carros Usados e Seminovos." +
      " Vendemos viaturas de qualidade para todo o país. Visite-nos em __MORADA__",
  },
  Financiamento: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stands __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__EMPRESA__`,
      `__TITLE__`,
      `consultoria automovel`,
      `Crédito Auto Online`,
      `Crédito Auto Online automovel`,
      `solucoes de Crédito Auto Online`,
      `financiamento auto`,
      `financiamento automovel`,
      `soluções de financiamento`,
    ],
    description:
      `Na __EMPRESA__ encontrará as soluções profissionais que acompanham ` +
      `todo o processo de escolha do financiamento mais adequada ás suas necessidades.`,
  },
  Oficina: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stands __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__EMPRESA__`,
      `__TITLE__`,
      `oficina`,
      `importação automoveis`,
      `Carregamento A/C`,
      `Elaboração de matrículas`,
      `Manutenção e todo o tipo de reparação automóvel`,
      `Teste de Diagnóstico de Avarias`,
      `Lavagem Automóvel`,
      `Serviço de pré inspecção e inspecção`,
    ],
    description: `Na __EMPRESA__ dispomos de serviço de oficina.`,
  },
  Aluguer: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stands __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `Aluguer de carros`,
      `aluguer automóveis`,
      `aluguer de veículos`,
      `alugueres de qualidade a baixos preços`,
      `aluguer de carros baratos`,
      `__EMPRESA__`,
    ],
    description:
      `Na __EMPRESA__ oferece serviço de aluguer de carros barato.` +
      ` Reserve o seu automóvel na __EMPRESA__.`,
  },
  Seguros: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      "seguros auto",
      "seguros automovel",
      "seguros carro",
      "automovel",
      `__EMPRESA__`,
    ],
    description:
      `Na __EMPRESA__ paralelamente à venda de automóveis novos e usados, ` +
      `veja aqui os seguros auto que melhor se adequam sua necessidade.`,
  },
  Testemunhos: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `Testemunhos`,
      `Depoimentos `,
      `Declarações`,
      `Manifestações`,
      `__EMPRESA__`,
    ],
    description:
      `__EMPRESA__ em __MORADA__, Na __EMPRESA__ disponibilizamos` +
      ` esta página para os clientes deixar o seu testemunho e visualizar a galeria dos nossos clientes.`,
  },
  Galeria: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      "galeria de fotos",
      "album",
      `__EMPRESA__`,
    ],
    description: `Na __EMPRESA__ disponibilizamos esta página para que os clientes possam ver o nosso album de fotos.`,
  },
  Lavagens: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      "Lavagem automática",
      "Lavagem manual",
      "Limpeza de interiores",
      "Lavagem de estofos",
      "Lavagem de tetos ",
      "Lavagem de alcatifas ",
      "Pré-Lavagem",
      "Espuma",
      "Shampoo",
      "Chassis",
      "Cera",
      "Polimento",
      "Secagem",
      "carros",
      "carro",
    ],
    description: `Na __EMPRESA__ paralelamente à venda de automóveis novos e usados, dispomos o sitema de limpeza e lavagens de carros.`,
  },
  Noticias: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      `notícias`,
      `próximas novidades`,
      `novas informações`,
      `próximos desenvolvimentos`,
      `depoimentos`,
      `informações`,
    ],
    description:
      `Na __EMPRESA__  disponibilizamos esta página para os clientes ` +
      `se manterem actualizados com as nossas notícias mais recentes.`,
  },
  Compramos: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      `consultoria automovel`,
      `Compramos `,
      `Compramos o seu automovel`,
      `venda rápida`,
      `vender o carro`,
      `troca automovel`,
      `vender online`,
      `avaliar carro`,
      `avaliação online`,
    ],
    description:
      `Na __EMPRESA__ disponibilizamos esta página para que os clientes possam avaliar ` +
      `os seus carros a incluir como retoma na compra de um automóvel.`,
  },
  Vendemos: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      `consultoria automovel`,
      `Vendemos `,
      `Vendemos o seu automovel`,
      `venda rápida`,
      `vender o carro`,
      `vender online`,
    ],
    description: `Na __EMPRESA__ disponibilizamos esta página para que os clientes possam vender os seus carros no nosso stand.`,
  },
  Consultadoria: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      "consultoria automovel",
      "importação automoveis",
      "Stand Virtual",
      "Viatura pretendida",
      "carro pretendido",
    ],
    description:
      `Na __EMPRESA__ se não encontrar a viatura pretendida, ` +
      `preencha o seguinte formulário para encontramos a melhor solução para si.`,
  },
  Garantia: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      "Sobre nós",
      "Garantia",
      "carros",
      "carro",
    ],
    description: `Na __EMPRESA__ venda de automóveis novos e usados com a melhor garantia`,
  },
  Newsletter: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      "auto usados",
      "carros usados",
      "stand semi-novos",
      "viaturas usadas",
      "stand multimarcas",
    ],
    description: `Subscreva na __TITLE__ para receber as novidades da __EMPRESA__.`,
  },
  Contactos: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      "auto usados",
      "carros usados",
      "stand semi-novos",
      "viaturas usadas",
      "stand multimarcas",
    ],
    description:
      `__EMPRESA__ com stand's de vendas em __MORADA__,` +
      ` onde poderá encontrar uma vasta gama de viaturas semi-novas e usadas das mais variadas marcas.` +
      ` Somos um stand multimarcas especializados em automóveis usados`,
  },
  Error: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      "auto usados",
      "carros usados",
      "stand semi-novos",
      "viaturas usadas",
      "stand multimarcas",
    ],
    description:
      `Procura automóvel? Escolha com Confiança. Stand de Carros Usados e Seminovos.` +
      ` Vendemos viaturas de qualidade para todo o país. Visite-nos em __MORADA__`,
  },
  TextLegalGarantia: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      "auto usados",
      "carros usados",
      "stand semi-novos",
      "viaturas usadas",
      "stand multimarcas",
    ],
    description:
      `__EMPRESA__ com stand's de vendas em __MORADA__,` +
      ` disponibiliza o __TITLE__`,
  },
  Privacidade: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      "auto usados",
      "carros usados",
      "stand semi-novos",
      "viaturas usadas",
      "stand multimarcas",
    ],
    description:
      `__EMPRESA__ com stand's de vendas em __MORADA__,` +
      ` disponibiliza o __TITLE__`,
  },
    Termos: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      "auto usados",
      "carros usados",
      "stand semi-novos",
      "viaturas usadas",
      "stand multimarcas",
    ],
    description:
      `__EMPRESA__ com stand's de vendas em __MORADA__,` +
      ` disponibiliza o __TITLE__`,
  },
        Cookie: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      "auto usados",
      "carros usados",
      "stand semi-novos",
      "viaturas usadas",
      "stand multimarcas",
    ],
    description:
      `__EMPRESA__ com stand's de vendas em __MORADA__,` +
      ` disponibiliza o __TITLE__`,
  },
  Obrigado: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      "auto usados",
      "carros usados",
      "stand semi-novos",
      "viaturas usadas",
      "stand multimarcas",
    ],
    description:
      `__EMPRESA__ com stand's de vendas em __MORADA__,` +
      ` agradece o seu contacto`,
  },
  Novos: {
    title: "__TITLE__ - __EMPRESA__ em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      `concionario`,
      "auto novos",
      "carros novos",
      "stand multimarcas",
    ],
    description:
      `__EMPRESA__ com stand's de vendas em __MORADA__,` +
      ` disponibiliza o viaturas novas das marcas que comercializamos`,
  },
  Home: {
    title: "__EMPRESA__ - Stand Carros Usados em __MORADA__",
    tags: [
      `stand __CONCELHO__`,
      `stand __DISTRITO__`,
      `__EMPRESA__, __MORADA__`,
      `__TITLE__`,
      `__EMPRESA__`,
      `concionario`,
      "auto usados",
      "carros usados",
      "stand semi-novos",
      "viaturas usadas",
      "stand multimarcas",
    ],
    description:
      `Procura automóvel? Escolha com Confiança. Stand de Carros Usados e Seminovos.` +
      ` Vendemos viaturas de qualidade para todo o país. Visite-nos em __MORADA__!`,
  },
};
export default metatags;
