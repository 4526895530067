import { Col } from "react-bootstrap";
import { selectImage, selectImageBarcos } from "./listaMarcas";
import css from "./marcas.module.css";
import { NewLinkServicos } from "../../../tools/Links/Links";

export default function CardMarca({ data, infoJson }) {
  return (
    <Col xs={12}>
      <NewLinkServicos location={data.url} externas={data.blank}>
        <Col className={`listagem_info  ${css.retangle}`}>
          {data.viatura === "barcos"
            ? selectImageBarcos(data.id, data.name, infoJson)
            : selectImage(data.id, data.name, infoJson)}
        </Col>
      </NewLinkServicos>
    </Col>
  );
}
