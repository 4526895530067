import { isZero } from "./tratarInfoCarros";

function tratarDados(dados, ext) {
  try {
    // trata a informação dos dados
    let horasFormat = isZero(dados);
    if (horasFormat !== "" && !/\D/.test(horasFormat)) {
      return `${horasFormat} ${ext}`;
    } else {
      return "";
    }
  } catch (error) {
    console.log("error", error);
    return "";
  }
}

export { tratarDados };
