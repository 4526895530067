function tratarLocation(codigo, titulo, domain) {
  try {
    let location = "";
    let novastr = "";

    // Lista de caracteres especiais que serão substituídos:
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";

    // Lista de caracteres que serão adicionados em relação aos anteriores:
    const to = "aaaaaeeeeeiiiiooooouuuunc------";

    if (titulo !== "") {
      // Converte o texto para caixa baixa:
      novastr = titulo.toLocaleLowerCase();
      // Remove qualquer caractere em branco do final do texto:
      novastr = novastr.replace(/^\s+|\s+$/g, "");

      // Substitui todos os caracteres especiais:
      for (let i = 0, l = from.length; i < l; i++) {
        novastr = novastr.replace(
          new RegExp(from.charAt(i), "g"),
          to.charAt(i)
        );
      }
    }
    // Remove qualquer caractere inválido que possa ter sobrado no texto:
    novastr = novastr.replace(/[^a-z0-9 -]/g, "");
    // Substitui os espaços em branco por hífen:
    novastr = novastr.replace(/\s+/g, "-");
    location = domain + codigo + "/" + novastr + "/";
    return location;
  } catch (error) {
    console.log("error", error);
    return "";
  }
}

function tratarData(data) {
  try {
    let dataTratada = "";

    if (data !== "") {
      const formatter = new Intl.DateTimeFormat("pt-pt", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      const splitMaster = data.split(" ");
      const split = splitMaster[0].split("/");

      if (onlyNumbers(split)) {
        const date = new Date(split[2], split[1] - 1, split[0]);

        dataTratada = formatter.format(Date.parse(date));
      }
    }

    return dataTratada;
  } catch (error) {
    console.log("error", error);
    return "";
  }
}

function onlyNumbers(array) {
  return array.every((element) => {
    return !isNaN(element);
  });
}

async function obterDimensoesImagem(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve({ width: this.width, height: this.height });
    };
    img.onerror = function () {
      reject(new Error("Não foi possível carregar a imagem."));
    };
    img.src = url;
  });
}
export { tratarLocation, tratarData, onlyNumbers, obterDimensoesImagem };
