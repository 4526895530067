export const selectImage = (id, name, infoJson) => {
  switch (id) {
    case 460:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Abarth.webp`)}
          alt={name}
        />
      );
    case 121:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Alfa.webp`)}
          alt={name}
        />
      );
    case 142:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Audi.webp`)}
          alt={name}
        />
      );
    case 380:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Bedford.webp`)}
          alt={name}
        />
      );
    case 124:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Bentley.webp`)}
          alt={name}
        />
      );
    case 125:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/BMW.webp`)}
          alt={name}
        />
      );
    case 285:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Bugatti.webp`)}
          alt={name}
        />
      );
    case 127:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Chevrolet.webp`)}
          alt={name}
        />
      );
    case 128:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Chrysler.webp`)}
          alt={name}
        />
      );
    case 129:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Citroen.webp`)}
          alt={name}
        />
      );
    case 345:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Dacia.webp`)}
          alt={name}
        />
      );
    case 280:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Datsun.webp`)}
          alt={name}
        />
      );
    case 278:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Dodge.webp`)}
          alt={name}
        />
      );
    case 447:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/DS.webp`)}
          alt={name}
        />
      );
    case 143:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Ferrari.webp`)}
          alt={name}
        />
      );
    case 277:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Fiat.webp`)}
          alt={name}
        />
      );
    case 2770:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/FiatPro.webp`)}
          alt={name}
        />
      );
    case 171:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/MG.webp`)}
          alt={name}
        />
      );
    case 276:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Ford.webp`)}
          alt={name}
        />
      );
    case 274:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Honda.webp`)}
          alt={name}
        />
      );
    case 271:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Isuzu.webp`)}
          alt={name}
        />
      );
    case 319:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Iveco.webp`)}
          alt={name}
        />
      );
    case 270:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Jaguar.webp`)}
          alt={name}
        />
      );
    case 269:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Jeep.webp`)}
          alt={name}
        />
      );
    case 268:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Kia.webp`)}
          alt={name}
        />
      );
    case 267:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Lamborghini.webp`)}
          alt={name}
        />
      );
    case 266:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Lancia.webp`)}
          alt={name}
        />
      );
    case 265:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/LandRover.webp`)}
          alt={name}
        />
      );
    case 264:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Lexus.webp`)}
          alt={name}
        />
      );
    case 144:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Lotus.webp`)}
          alt={name}
        />
      );
    case 262:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Maserati.webp`)}
          alt={name}
        />
      );
    case 261:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Mazda.webp`)}
          alt={name}
        />
      );
    case 173:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/MercedesBenz.webp`)}
          alt={name}
        />
      );
    case 170:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/MINI.webp`)}
          alt={name}
        />
      );
    case 165:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Mitsubishi.webp`)}
          alt={name}
        />
      );
    case 160:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Nissan.webp`)}
          alt={name}
        />
      );
    case 157:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Opel.webp`)}
          alt={name}
        />
      );
    case 156:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Peugeot.webp`)}
          alt={name}
        />
      );
    case 141:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Porsche.webp`)}
          alt={name}
        />
      );
    case 153:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Renault.webp`)}
          alt={name}
        />
      );
    case 151:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Rover.webp`)}
          alt={name}
        />
      );
    case 139:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Seat.webp`)}
          alt={name}
        />
      );
    case 138:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Skoda.webp`)}
          alt={name}
        />
      );
    case 137:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Smart.webp`)}
          alt={name}
        />
      );
    case 149:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Subaru.webp`)}
          alt={name}
        />
      );
    case 148:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Suzuki.webp`)}
          alt={name}
        />
      );
    case 451:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Tesla.webp`)}
          alt={name}
        />
      );
    case 145:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Toyota.webp`)}
          alt={name}
        />
      );
    case 135:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/UMM.webp`)}
          alt={name}
        />
      );
    case 133:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Volvo.webp`)}
          alt={name}
        />
      );
    case 134:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Volkswagen.webp`)}
          alt={name}
        />
      );
    case 272:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Hyundai.webp`)}
          alt={name}
        />
      );
    case 352:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Yamaha.webp`)}
          alt={name}
        />
      );
    case 309:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Bombardier.webp`)}
          alt={name}
        />
      );
    case 654:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Cupra.webp`)}
          alt={name}
        />
      );
    // marca automoveis
    // marca Motas
    case 439:
    case 475:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/canam.webp`)}
          alt={name}
        />
      );
    case 383:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Bombardie.webp`)}
          alt={name}
        />
      );

    case 437:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/derbi.webp`)}
          alt={name}
        />
      );

    case 390:
    case 518:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/ktm.webp`)}
          alt={name}
        />
      );

    case 356:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Aprilia.webp`)}
          alt={name}
        />
      );

    case 391:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/gilera.webp`)}
          alt={name}
        />
      );

    case 461:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Ural.webp`)}
          alt={name}
        />
      );

    case 411:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Sym.webp`)}
          alt={name}
        />
      );

    case 349:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/MercuryRacing.webp`)}
          alt={name}
        />
      );

    case 387:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Quicksilver.webp`)}
          alt={name}
        />
      );

    case 674:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Bayliner.webp`)}
          alt={name}
        />
      );

    case 357:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Zodiac.webp`)}
          alt={name}
        />
      );
    case 486:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Yanmar.webp`)}
          alt={name}
        />
      );

    case 668:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/MotoGuzzi.webp`)}
          alt={name}
        />
      );

    case 366:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Piaggio.webp`)}
          alt={name}
        />
      );

    case 350:
    case 454:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Polaris.webp`)}
          alt={name}
        />
      );

    case 457:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/RoyalEnfield.webp`)}
          alt={name}
        />
      );

    case 412:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Scarabeo.webp`)}
          alt={name}
        />
      );

    case 360:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/SeaDoo.webp`)}
          alt={name}
        />
      );
    case 354:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/vespa.webp`)}
          alt={name}
        />
      );

    case 811:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/malaguti.webp`)}
          alt={name}
        />
      );

    case 810:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/neco.webp`)}
          alt={name}
        />
      );

    case 809:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/voge.webp`)}
          alt={name}
        />
      );

    case 781:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/swm.webp`)}
          alt={name}
        />
      );

    case 812:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/bullit.webp`)}
          alt={name}
        />
      );

    default:
      return <p>{name}</p>;
  }
};

export const selectImageBarcos = (id, name, infoJson) => {
  switch (id) {
    case 478:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Dipol.webp`)}
          alt={name}
        />
      );

    case 666:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/ezdock.webp`)}
          alt={name}
        />
      );

    case 633:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/YamahaBoat.webp`)}
          alt={name}
        />
      );

    case 556:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Mercury.webp`)}
          alt={name}
        />
      );

    case 646:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Cranchi.webp`)}
          alt={name}
        />
      );

    case 642:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Zodiac.webp`)}
          alt={name}
        />
      );

    case 608:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Solemar.webp`)}
          alt={name}
        />
      );

    case 439:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Arimar.webp`)}
          alt={name}
        />
      );
    case 456:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Beneteau.webp`)}
          alt={name}
        />
      );

    case 671:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Bali.webp`)}
          alt={name}
        />
      );

    case 662:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Sunchaser.webp`)}
          alt={name}
        />
      );

    case 467:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Capelli.webp`)}
          alt={name}
        />
      );

    case 718:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Antonio.webp`)}
          alt={name}
        />
      );

    case 710:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Sealegs.webp`)}
          alt={name}
        />
      );

    case 579:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Quicksilver.webp`)}
          alt={name}
        />
      );

    case 454:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Bayliner.webp`)}
          alt={name}
        />
      );

    case 461:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Bombard.webp`)}
          alt={name}
        />
      );

    case 617:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/SuzukiMarine.webp`)}
          alt={name}
        />
      );

    case 634:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Yanmar.webp`)}
          alt={name}
        />
      );

    case 523:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/HondaMarine.webp`)}
          alt={name}
        />
      );

    case 575:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/Polaris.webp`)}
          alt={name}
        />
      );

    case 599:
      return (
        <img
          src={require(`../../../../assets/img/marcas/${
            infoJson.Layout.ThemeDark ? "branco" : "preto"
          }/SeaDoo.webp`)}
          alt={name}
        />
      );

    default:
      return <p>{name}</p>;
  }
};
