import axios from "axios";
const global = require("../config/config");
async function getNeves(infoJson) {
  const API_URL_GETNEVES = {
    method: "GET",
    url: `${global.ApiLink}/v1/${
      infoJson.Stand.Easymanager ? "easymanager" : "easydata"
    }/easysite/GetNeves/`,
    params: { dealer_id: infoJson.Stand.Anunciante },
    headers: { token: process.envx.REACT_APP_TOKEN_API },
  };

  const res = await axios.request(API_URL_GETNEVES);

  return res.data;
}

export { getNeves };
