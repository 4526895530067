import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router";
import { history } from "../../tools/restoreScroll/customRouter";
import { yScrollContext } from "../../tools/restoreScroll/yScroll";
export const Wrapper = ({ children }) => {
  const { yScroll, setYscroll } = useContext(yScrollContext);
  const location = useLocation();
  const [currHist, setCurrHist] = useState({
    action: "",
    location: {},
  });

  useState(() => {
    //verifica a altura em quando faz scroll

    const scrolled = () => setYscroll(() => window.scrollY);
    window.addEventListener("scroll", scrolled);
    return () => {
      window.removeEventListener("scroll", scrolled);
    };
  }, []);

  useEffect(() => {
    //guarda numa variavel de sessao a altura do card
    return () => {
      sessionStorage.setItem("yvalue", JSON.stringify(yScroll));
    };
  }, [yScroll]);

  useEffect(() => {
    return history.listen(({ location, action }) => {
      if (action === "POP") {
        const yscroll = sessionStorage.getItem("yvalue");
        location = {
          ...location,
          state: {
            forward: {
              yscroll: yscroll,
            },
            ...location.state,
          },
        };
      }
      setCurrHist((hist) => {
        let obj;
        location.state && location.state.hasOwnProperty("backward")
          ? (obj = location.state)
          : (obj = hist.location.state);

        location = {
          ...location,
          state: {
            ...location.state,
            ...obj,
          },
        };

        return {
          ...hist,
          action: action,
          location: {
            ...location,
          },
        };
      });
      // console.log(action, location.pathname, location.state);
    });
  }, []);

  useLayoutEffect(() => {
    if (location.state && location.state.goToListagem) {
      return;
    }
    if (currHist.action && currHist.action === "POP") {
      if (currHist.location.state.hasOwnProperty("backward")) {
        setTimeout(() => {
          window.scrollTo(
            0,
            parseFloat(currHist.location.state.backward.yscroll)
          );
        }, 1200);
      } else {
        if (location.state && location.state.message) {
        } else {
          if (location.hash) {
            const id = location.hash.replace("#", "");
            if (id) {
              const anchor = document.getElementById(id);
              if (anchor) {
                /* Scroll to that element if present */
                setTimeout(() => {
                  anchor.scrollIntoView();
                }, 1000);
              }
            }
          } else {
            setTimeout(() => {
              document.documentElement.scrollTo(0, 0);
            }, 1000);
          }
        }
      }
    } else {
      if (location.state && location.state.message) {
      } else {
        if (location.hash) {
          const id = location.hash.replace("#", "");
          if (id) {
            const anchor = document.getElementById(id);
            if (anchor) {
              /* Scroll to that element if present */
              setTimeout(() => {
                anchor.scrollIntoView();
              }, 1000);
            }
          }
        } else {
          setTimeout(() => {
            document.documentElement.scrollTo(0, 0);
          }, 1000);
        }
      }
    }

    // console.log("currHist.location", currHist.location);
    // console.log("location", location);
  }, [currHist, location]);

  return children;
};
