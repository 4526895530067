import  { createContext, useReducer } from "react";

// declare EcommerceContext as a Context object
export const EcommerceContext = createContext();

const initialState = {
  eurotaxCode: null,
  existeBtn: false,
};

// reducer to manage Ecommerce state
const reducer = (prevState, action) => {
  switch (action.type) {
    case "AddInfo":
      return {
        ...prevState,
        eurotaxCode: action.code,
        existeBtn: action.existe,
      };

    default:
      return initialState;
  }
};

export const EcommerceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EcommerceContext.Provider
      value={{ stateEcommerce: state, dispatchEcommerce: dispatch }}
    >
      {children}
    </EcommerceContext.Provider>
  );
};

export default EcommerceContext;
