import { ApiLinkImagens } from "../../config/config";
import { tratarDados } from "./tratarInfoBarcos";
import {
  tratar2Chave,
  tratarAnoMes,
  tratarCilindrada,
  tratarClassePortagem,
  tratarConsumos,
  tratarKm,
  tratarLink,
  tratarLivroRevisoes,
  tratarMotorizacao,
  tratarNacional,
  tratarNumeroRegistos,
  tratarPotencia,
  tratarPreco,
  tratarPrecoPromo,
  tratarValorMensalidade,
  tratarVendido,
} from "./tratarInfoCarros";
import { selectSchema } from "./tratatSchema";

function createMetaTags(
  Nome,
  printImg,
  tags,
  description,
  Morada,
  Website,
  Title,
  categoria
) {
  try {
    let print;

    // verifica se a função traz algum link de imagem (Ficha de viatura , ficha de noticias ,...)
    // Se não coloca o link por defeito de uma imagem que se encontra na pasta public/assets/img/partilha_fb.jpg

    printImg !== ""
      ? (print = printImg)
      : (print = `${ApiLinkImagens}/partilha_fb`);

    // inicia a criaçao do objecto que cria as metatags para varias plataformas . Facebook,Twitter
    //? Mais info ver  https://github.com/teemukoivisto/react-seo-meta-tags

    const metaTag = {
      fullWebsiteData: {
        url: window.location.href,
        title: Title,
        description: description,
        language: "pt-PT",
        image: print,
        imageAlt: `LogoTipo ${Nome}`,
        author: {
          email: "suporte@easysite.pt",
          name: "PDC digital",
          image:
            "https://www.easysite.pt/web1/zp/tpl1/template/img/cortes/fav.png",
        },
      },
      blogPostData: selectSchema(categoria),
      facebookData: {
        title: Title,
        description: description,
        language: "pt-PT",
        image: print,
        imageAlt: `LogoTipo ${Nome}`,
      },
      twitterData: {
        title: Title,
        description: description,
        image: print,
        imageAlt: `LogoTipo ${Nome}`,
        cardType: "summary_large_image",
      },
      organization: {
        name: Nome,
        legalName: Nome,
        description: `Consulte o stock de carros usados para venda no ${Nome} localizado em, ${Morada}. Carros baratos e negócios de ocasião de viaturas multimarcas.`,
        url: Website,
        logo: print,
      },
    };
    return metaTag;
  } catch (error) {
    console.log("error", error);
  }
}

function ButtonMuiltiSelect(infoJson) {
  // inicia a criaçao do objecto que altera o styles do multiselect da Marca , Modelo, Combustivel
  //? Mais info ver  https://github.com/based-ghost/react-functional-select
  //? --> https://based-ghost.github.io/react-functional-select/?path=/story/react-functional-select--styling
  try {
    const Button = {
      color: {
        border: "transparent",
        danger: "#dc3545",
        primary: `${infoJson.Cores.Principal} `,
        placeholder: infoJson.Layout.ThemeDark ? "#ffffff" : "#4A4A4A",
      },
      loader: {
        color: `rgba(${infoJson.Cores.RgbShadow}, 0.42)  `,
      },
      icon: { color: "transparent", padding: "0 7px" },
      menu: {
        backgroundColor: infoJson.Layout.ThemeDark ? "#000000" : "#ffffff",
        option: {
          selectedColor: "#fff",
          selectedBgColor: `${infoJson.Cores.Principal} `,
          focusedBgColor: `rgba(${infoJson.Cores.RgbShadow}, 0.15)  `,
        },
      },
      noOptions: {
        color: "hsl(0, 0%, 60%)",
      },
      multiValue: {
        borderRadius: "13px",
        backgroundColor: infoJson.Layout.ThemeDark ? "#282828" : "#F5F5F5",
        clear: {
          color: infoJson.Layout.ThemeDark ? "#ffffff" : "#4A4A4A",
        },
      },
      control: {
        minHeight: "46px",
        borderWidth: "2px",
        borderRadius: "30px",
        focusedBorderColor: `rgba(0,0,0, 0.75)`,
        boxShadowColor: "none",
      },
    };
    return Button;
  } catch (error) {
    console.log("error", error);
  }
}

function ButtonMuiltiSelectRapida(infoJson) {
  // inicia a criaçao do objecto que altera o styles do multiselect da Marca , Modelo, Combustivel
  //? Mais info ver  https://github.com/based-ghost/react-functional-select
  //? --> https://based-ghost.github.io/react-functional-select/?path=/story/react-functional-select--styling
  try {
    const Button = {
      color: {
        border: "transparent",
        danger: "#dc3545",
        primary: `${infoJson.Cores.Principal} `,
        placeholder: infoJson.Layout.ThemeDark ? "#ffffff" : "#4A4A4A",
      },
      loader: {
        color: `rgba(${infoJson.Cores.RgbShadow}, 0.42)  `,
      },
      icon: { color: "transparent", padding: "0 7px" },
      menu: {
        backgroundColor: infoJson.Layout.ThemeDark ? "#000000" : "#ffffff",
        option: {
          selectedColor: "#fff",
          selectedBgColor: `${infoJson.Cores.Principal} `,
          focusedBgColor: `rgba(${infoJson.Cores.RgbShadow}, 0.15)  `,
        },
      },
      noOptions: {
        color: "hsl(0, 0%, 60%)",
      },
      multiValue: {
        borderRadius: "13px",
        backgroundColor: infoJson.Layout.ThemeDark ? "#282828" : "#F5F5F5",
        clear: {
          color: infoJson.Layout.ThemeDark ? "#ffffff" : "#4A4A4A",
        },
      },
      control: {
        minHeight: "46px",
        borderWidth: "0",
        borderRadius: "30px",
        focusedBorderColor: `transparent`,
        boxShadowColor: "none",
      },
    };
    return Button;
  } catch (error) {
    console.log("error", error);
  }
}

const isElementInViewport = (el) => {
  try {
    if (el) {
      var rect = el.getBoundingClientRect();
      var viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }
  } catch (error) {
    console.log("error", error);
  }
};

const hostname_data = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  let data = queryParameters.get("marvel");

  if (data === null) {
    data = window.location.hostname.replace("www.", "");
  }
  return data;
};

const tratarInfoViatura = (viatura, tipo) => {
  const textCilindrada = tratarCilindrada(viatura.Cilindrada);
  const textPotencia = tratarPotencia(viatura.Potencia, tipo);
  const textConsumo = tratarConsumos(
    viatura.ConsumoUrbano,
    viatura.ConsumoExtraUrbano,
    viatura.ConsumoMisto
  );
  const textNacional = tratarNacional(viatura.Nacional);
  const textLivroRevisoes = tratarLivroRevisoes(viatura.ExtrasSoltos);
  const textAno = tratarAnoMes(viatura.Ano, viatura.Mes);
  const textHoras = tratarDados(viatura?.Horas, "Horas");
  const textNumeroMotores = tratarDados(viatura?.NumeroMotores, "Motores");
  const textComprimento = tratarDados(viatura?.Comprimento, "m");
  const textBoca = tratarDados(viatura?.Boca, "m");
  const textPontal = tratarDados(viatura?.Pontal, "m");
  const textCalado = tratarDados(viatura?.Calado, "m");
  const textCarga = tratarDados(viatura.CapacidadeCarga, "");
  const textEixos = tratarDados(viatura.Eixos, "");
  const textTipoMotor = tratarDados(viatura?.Tipo_motor, "Tempos");
  const textKm = tratarKm(viatura.Km, true);
  const textSegundaChave = tratar2Chave(viatura.SegundaChave);
  const textClassePortagem = tratarClassePortagem(viatura.ClassePortagem);
  const textAnoInspecao = tratarAnoMes(
    viatura?.InspeccaoAno,
    viatura?.InspeccaoMes
  );
  const textDepositoAgua = tratarDados(viatura?.DepositoAgua, "L");
  const textDepositoCombustivel = tratarDados(
    viatura?.DepositoCombustivel,
    "L"
  );
  const PrecoLimpo = tratarPreco(viatura.Preco);
  const PrecoPromoLimpo = tratarPrecoPromo(viatura.PrecoPromo);
  const ValorMensalidadeLimpo = tratarValorMensalidade(
    viatura.ValorMensalidade
  );
  const location = tratarLink(
    ["pecas", "atrelados", "motores"].includes(tipo)
      ? viatura.Titulo
      : viatura.Marca.Nome,
    ["pecas", "atrelados", "motores"].includes(tipo)
      ? viatura.Marca.Nome
      : ["barcos"].includes(tipo)
      ? viatura.Motorizacao
      : viatura.Modelo.Nome,
    viatura.CodViatura,
    tipo
  );
  const Motorizacao = tratarMotorizacao(
    viatura.VersaoAlternatica,
    viatura.Motorizacao
  );
  const textVendido = tratarVendido(tipo);
  const textNumeroRegisto = tratarNumeroRegistos(viatura?.Registo);

  return {
    ...viatura,
    textMarca: ["pecas", "atrelados", "motores"].includes(tipo)
      ? viatura.Titulo
      : viatura.Marca.Nome,
    textModelo: ["pecas", "atrelados", "motores"].includes(tipo)
      ? viatura.Marca.Nome
      : ["barcos"].includes(tipo)
      ? ""
      : viatura.Modelo.Nome,
    textMotorizacao: ["pecas", "atrelados", "motores", "barcos"].includes(tipo)
      ? viatura.Motorizacao
      : Motorizacao,
    textVendido,
    textCilindrada,
    textPotencia,
    textConsumo,
    textNacional,
    textLivroRevisoes,
    textAno,
    textHoras,
    textNumeroMotores,
    textComprimento,
    textBoca,
    textPontal,
    textCalado,
    textCarga,
    textEixos,
    textTipoMotor,
    textKm,
    textSegundaChave,
    textClassePortagem,
    textAnoInspecao,
    textDepositoAgua,
    textDepositoCombustivel,
    PrecoLimpo,
    PrecoPromoLimpo,
    ValorMensalidadeLimpo,
    location,
    textNumeroRegisto,
  };
};

export {
  createMetaTags,
  ButtonMuiltiSelect,
  isElementInViewport,
  ButtonMuiltiSelectRapida,
  hostname_data,
  tratarInfoViatura,
};
